body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --site-primary-color: #CA001B;
  --site-secondary-color: #3B3C3E;

}

body {
  font-family: 'Poppins', sans-serif;
  line-height: normal;
  font-size: 16px;
  color: #000;
  background: #FAFBFD;
}

img {
  border: 0;
  width: auto;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}



h1,
h2,
h3,
h4,
h5,
h6 {

  font-weight: 600;
  margin-bottom: 1.5rem;
}


@media all and (min-width: 992px) {

  .mega-heading,
  h1 {
      font-size: 4.8rem
  }

  .large-heading,
  h2 {
      font-size: 4.2rem;
  }

  .medium-heading,
  h3 {
      font-size: 3.6rem
  }

  .small-heading,
  h4 {
      font-size: 2.5rem
  }

  h5,
  .small-heading-md {
      font-size: 2rem
  }

  h6,
  .small-heading-sm {
      font-size: 1.8rem
  }
}

@media all and (min-width:768px) and (max-width: 991px) {

  .mega-heading,
  h1 {
      font-size: 4.4rem
  }

  .large-heading,
  h2 {
      font-size: 3.8rem;
  }

  .medium-heading,
  h3 {
      font-size: 3rem
  }

  .small-heading,
  h4 {
      font-size: 2.2rem
  }

  h5,
  .small-heading-md {
      font-size: 2rem
  }

  h6,
  .small-heading-sm {
      font-size: 1.8rem
  }

}

@media all and (min-width:576px) and (max-width: 767px) {

  .mega-heading,
  h1 {
      font-size: 3.6rem
  }

  .large-heading,
  h2 {
      font-size: 3rem;
  }

  .medium-heading,
  h3 {
      font-size: 2.6rem
  }

  .small-heading,
  h4 {
      font-size: 2rem
  }

  h5,
  .small-heading-md {
      font-size: 1.8rem
  }

  h6,
  .small-heading-sm {
      font-size: 1.7rem
  }

}

@media all and (max-width: 575px) {

  .mega-heading,
  h1 {
      font-size: 2.8rem
  }

  .large-heading,
  h2 {
      font-size: 2.4rem;
  }

  .medium-heading,
  h3 {
      font-size: 2rem
  }

  .small-heading,
  h4 {
      font-size: 1.8rem
  }

  h5,
  .small-heading-md {
      font-size: 1.7rem
  }

  h6,
  .small-heading-sm {
      font-size: 1.6rem
  }
}



@media all and (min-width:992px) {

  .padding-top-bottom {
      padding-top: 100px;
      padding-bottom: 100px;
      position: relative;
  }

}

@media all and (min-width:768px) and (max-width: 991px) {

  .padding-top-bottom {
      padding-top: 80px;
      padding-bottom: 80px;
  }

}

@media all and (min-width:576px) and (max-width: 767px) {

  .padding-top-bottom {
      padding-top: 70px;
      padding-bottom: 70px;
  }

}

@media all and (max-width: 575px) {
  .padding-top-bottom {
      padding-top: 30px;
      padding-bottom: 30px;
  }
}

.padding-top-bottom .container {
  z-index: 1;
  position: relative;
}

header {
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
  font-weight: 600;
}

.navbar-light .navbar-nav .active>.nav-link {
  color: #008AFF;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #12e668;
}

.custom-navbar {
  background-color: white;
  border-bottom: 2px solid #cccccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.logo-dimentions { 
  max-width: 117px;
  max-height: 50px;
}
@media screen and (max-width: 768px) {
  .logo-dimensions {
    max-width: 80px; /* Adjust the width for smaller screens */
    max-height: auto; /* Allow the height to adjust proportionally */
  }
}

.main-banner {
  display: flex;
  position: relative;
  align-items: center;
  background-image: url(/static/media/land-banner.ce38d19d.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 788px;
}

.main-banner .hero-title {
  color: #FFF;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.main-banner p {
  color: #FFF;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
}


@media (max-width: 767px) {
  .main-banner {
      margin-top: 80px;
      padding-bottom: 10px;
  }
}

.store-btn {
  display: flex;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
  margin-top: 30px;
}

.store-btn a {
  background: #fff;
  padding: 10px;
  border-radius: 50px;
  min-width: 172px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: 600;
  color: #000;
}

.store-btn a img {
  margin-right: 8px;
}

.store-btn .rating {
  margin-top: 20px;
}

.rating i {
  color: #ffb300;
}

.rating .text {
  color: #FFF;
  font-size: 18px;
  font-weight: 600;
}


span.text-blue {
  color: #008AFF;
  font-size: 18px;
  font-weight: 600;
}

.common-heading {
  font-size: 40px !important;
  font-weight: 600 !important;
  line-height: 60px !important;
}

.sp-box .title {
  font-size: 20px;
  font-weight: 600;
  margin: 15px 0;
}

.sp-box p {
  color: #8492A7;
}

.steps-row {
  text-align: center;
}

.steps-row img {
  max-width: 150px;
}

.steps-row .title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.steps-row p {
  color: #8492A7;
}




.list-item {
  display: flex;
}

.list-item .img {
  max-width: 65px;
  width: 100%;
  height: 65px;
  border-radius: 100px;
  background: #FFF;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.list-item .content h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.list-item .content p {
  color: #8492A7;
}


.bg-white-sec {
  border-radius: 3px;
  background: #FFF;
  padding: 50px;
}

.bg-white-sec .text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg-white-sec img {
  max-width: 34px;
}


.bg-white-sec .text-center img+span {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: normal;
  margin: 15px 0 10px;
}


.bg-white-sec .text-center span:last-child {
  color: #8492A7;
}

.featured-box {
  background: #fff;
  height: 100%;
  border-radius: 10px;
  padding: 20px;
}

.featured-box h4 {
  font-size: 20px;
  margin: 20px 0 15px;
}

.featured-box p {
  color: #8492A7;
}

.land-btn a {
  border-radius: 27.5px;
  background: #008AFF;
  color: #fff;
  padding: 10px 20px;
  display: inline-block;
  text-decoration: none;
}

.social-items a {
  color: #fff;
  margin-right: 10px;
}


.testimonial-box {
  border-radius: 9px;
  background: #FFF;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
}

.testimonial-box .top {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.testimonial-box .top img {
  margin-top: -50px;
  max-width: 80px;
  margin-bottom: 15px;
}

.testimonial-box .top .title {
  font-size: 20px;
  font-weight: 600;
}

.testimonial-box .top .rating {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}


.testimonial-box .top .rating span:first-child {
  color: #8492A7;
  line-height: 35px;
  /* 181.25% */
  letter-spacing: -0.294px;
  margin-bottom: 10px;
}

.testimonial-box .middle {
  color: #8492A7;
  margin: 15px 0 20px;
}

.testimonial-box .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.testimonial-box .bottom a {
  display: flex;
  align-items: center;
}

.testimonial-box .bottom img.mr-2 {
  border-radius: 40px;
  background: #fff;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.08);
  padding: 10px;
}


.bg-white-sec .store-btn a {
  color: #fff;
  background: #008AFF;
}





footer {
  background: rgba(36, 4, 229, 1);
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
}

@media all and (max-width:575px) {

  .main-banner {
      background: #2404e5;
      height: 500px;
  }

  .main-banner .hero-title {
      font-size: 20px;
      line-height: normal;
  }

  .store-btn a {
      min-width: -webkit-max-content;
      min-width: max-content;
  }



  .common-heading {
      font-size: 26px !important;
      line-height: normal !important;
  }

  .bg-white-sec {
      padding: 15px;
  }
}


.language-selector {
  margin-right: 10px; /* Adjust the margin as needed */
}

.spacer {
  width: 10px; /* Adjust the width as needed */
  height: 1px; /* Adjust the height as needed */
  display: inline-block;
}
